body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: rgb(153, 51, 204);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color)) !important;
}


/* Root variables for theming */
/*:root {
  --primary-color: #27256b; 
  --secondary-color: #880e4f; 
  --text-color: #ffffff; 
  --hover-color: #f8bbd0; 
  --font-family: 'Lora', serif;
}*/
:root {
  --primary-color: #4a5d4e;
  --secondary-color: #d2c4b5;
  --background-color: rgb(153, 51, 204);
  --text-color: #333333;
  --hover-color: #6b7f6f;
  --font-family: 'Playfair Display', serif;
}
/* Import Google Fonts */
/*@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400;700&display=swap');

:root {
  --font-family-header: 'Playfair Display', serif;
  --font-family-body: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-header);
}

body {
  font-family: var(--font-family-body);
  background-color: rgb(153, 51, 204);
}
/* Navbar styling */
.navbar {
  background-color: var(--primary-color);
  font-family: var(--font-family);
}

.navbar-brand img {
  height: 40px;
}

.navbar-nav .nav-link {
  color: var(--text-color);
  font-weight: bold;
  margin-right: 15px;
  transition: color 0.3s ease, background-color 0.3s ease;
  position: relative;
}

.navbar-nav .nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--text-color);
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
	 color: var(--active-color);
}
.navbar-nav .nav-link:hover::after {
  width: 100%;
}

.navbar-nav .nav-link:hover {
  color: var(--hover-color);
}

.navbar-nav .dropdown-menu {
  background-color: var(--primary-color);
  border: none;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}

.navbar-nav .dropdown-item {
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

.navbar-nav .dropdown-item:hover {
  background-color: var(--hover-color);
  color: var(--primary-color);
}

.navbar-toggler {
  border-color: var(--text-color);
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' \
    xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23ffffff' stroke-width='2' \
    stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    margin-right: 0;
    text-align: center;
  }

  .navbar-nav {
    background-color: var(--primary-color);
  }
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }
  .navbar-nav {
    display: none;
  }
  .navbar-nav.active {
    display: flex;
    flex-direction: column;
  }
  .hero-title {
    font-size: 2.5rem;
  }

  .about-us .row {
    flex-direction: column;
  }

  .about-us .col-md-6 {
    margin-bottom: 20px;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form .form-control,
  .newsletter-form .btn {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 10px;
  }
}
.navbar-nav .nav-link {
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: var(--hover-color);
}

/* Root variables for theming (if not already defined) */
:root {
  --primary-color: #27256b;       /* Feminine pink */
  --secondary-color: #880e4f;     /* Deep pink for accents */
  --background-color: #cc99ff;    /* Light pink background */
  --text-color: #ffffff;   
  --active-color:#880e4f;  /* Dark text for contrast */
  --light-text-color: #ffffff;    /* White text */
  --hover-color: #f8bbd0;         /* Light pink hover */
  --font-family-header: 'Lora', serif;
  --font-family-body: 'Roboto', sans-serif;
}

/* Body Styling */
body {
    background-color: #e4e1e7 !important;
    background-size: cover !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed;
  font-family: var(--font-family-body);
  color: var(--text-color);
  margin: 0;
}

/* Container */
.container {
  max-width: 1200px;
}

/* Hero Section */
.hero {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: var(--light-text-color);
  border-radius: 10px;
}

.hero-title {
  font-family: var(--font-family-header);
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.hero .btn {
  background-color: var(--light-text-color);
  color: var(--primary-color);
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.hero .btn:hover {
  background-color: var(--hover-color);
  color: var(--primary-color);
}

/* About Us Section */
.about-us h2 {
  font-family: var(--font-family-header);
  color: var(--primary-color);
  margin-bottom: 20px;
}

.about-us p {
  line-height: 1.6;
}

.about-us .btn {
  border-color: var(--primary-color);
  color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.about-us .btn:hover {
  background-color: var(--primary-color);
  color: var(--light-text-color);
}

/* Events Section */
.events {
  background-color: #fff;
}

.events h2 {
  font-family: var(--font-family-header);
  color: var(--secondary-color);
  margin-bottom: 40px;
}

.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.card-title {
  font-family: var(--font-family-header);
  color: var(--primary-color);
}

.card-text {
  line-height: 1.5;
}

.card .btn {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border-radius: 25px;
  padding: 10px 20px;
}

.card .btn:hover {
  background-color: var(--secondary-color);
}

/* Newsletter Section */
.newsletter {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border-radius: 10px;
}

.newsletter h2 {
  font-family: var(--font-family-header);
  margin-bottom: 20px;
}

.newsletter-form .form-control {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border: none;
  padding: 10px 15px;
}

.newsletter-form .btn {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: var(--secondary-color);
  border: none;
  padding: 10px 20px;
}

.newsletter-form .btn:hover {
  background-color: var(--light-text-color);
  color: var(--primary-color);
}

/* Footer */
footer {
  background: var(--primary-color);
  color: var(--light-text-color);
  text-align: center;
  padding: 15px 0;
  width: 100%;
  font-family: var(--font-family-body);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .about-us .row {
    flex-direction: column;
  }

  .about-us .col-md-6 {
    margin-bottom: 20px;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form .form-control,
  .newsletter-form .btn {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 10px;
  }
}
